<script>
import { h } from 'vue'
export default {
  props: {
    doc: {
      type: Object,
      default() { return {} }
    },
    getDocUrl: {
      type: Function,
      default: (id) => id
    }
  },
  methods: {
    getDocBodyHtml(data) {
      if (!data) {
        return null
      }
      if (data.tag === 'html') {
        return <div class="doc-body ql-editor" domPropsInnerHTML={data.html}></div>
      }
      if (typeof data === 'string') {
        return <div class="doc-body ql-editor" domPropsInnerHTML={data}></div>
      }
      return <div class="doc-body">
        {
          this.getBodyHtml(data)
        }
      </div>
    },
    getBodyHtml(data) {
      if (typeof data === 'string') {
        return <p domPropsInnerHTML={data}></p>
      }
      // debugger
      if (Object.prototype.toString.call(data) === '[object Object]') {
        if (data.tag) {
          const tag = data.tag
          const children = data.children
          const props = {
            ...data
          }
          delete props.tag
          delete props.children
          // this.$delete(data, 'tag')
          // this.$delete(data, 'children')
          return h(tag, props, children)
        } else {
          return null
        }
      }
      if (Array.isArray(data)) {
        return data.map(item => this.getBodyHtml(item))
      }
    },
    getDateByTime(time) {
      if (time && typeof time === 'string') {
        const newDate = new Date(time.replace(/-/g,'/'))
        const year = newDate.getFullYear()
        let month = newDate.getMonth() + 1
        let day = newDate.getDate()
        month = ('00' + month).slice(-2)
        day = ('00' + day).slice(-2)
        return `${year}-${month}-${day}`
      }
      return null
    }
  },
  render() {
    const doc = this.doc
    const getDocUrl = this.getDocUrl
    if (Object.prototype.toString.call(doc) !== '[object Object]') {
      return null
    }
    const docTitle = <h1 class="doc-title h1">{doc.title}</h1>
    const docInfo = <div class="doc-info">
      {
        doc.createByName ? <div class="yp-info-createbyname">
          <span>编辑：</span>
          <span>{doc.createByName}</span>
        </div> : null
      }
      {
        doc.createTime ? <div class="yp-info-createtime">
          <span>时间：</span>
          <span>{this.getDateByTime(doc.createTime)}</span>
        </div> : null
      }
    </div>
    const docHeader = <div class="doc-header">{[docTitle, docInfo]}</div>
    const docBody = this.getDocBodyHtml(doc.body)
    let docFooter = null
    if (doc.prev || doc.next) {
      const prev = <div class="prev-doc">
        <div class="prev--before">上一篇</div>
        {
          doc.prev ? <a href={getDocUrl(doc.prev.id)}>{doc.prev.title}</a> : <div class="no-link">无</div>
        }
      </div>
      const next = <div class="prev-doc">
        <div class="next--before">下一篇</div>
        {
          doc.next ? <a href={getDocUrl(doc.next.id)}>{doc.next.title}</a> : <div class="no-link">无</div>
        }
      </div>
      docFooter = <div class="doc-footer">{[prev, next]}</div>
    }
    
    return <div class="doc-content">{[docHeader, docBody, docFooter]}</div>

  }
}
</script>
<style lang="scss">
@import '@/assets/css/yp-doc.scss';
</style>