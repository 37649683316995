<template>
  <div class="yp-footer-container">
    <div class="yp-footer-container-inner">
      <!-- <div class="yp-footer-bg-container">
        <div class="image-content image-content-1" ></div>
        <div class="image-content image-map-content"></div>
      </div> -->
      <div class="container">
        <div class="container-box-class">
          <div class="yp-footer-content">
            <list-content :list-data="listData"></list-content>
          </div>
          <div class="enterprise-info-container">
            <div class="enterprise-info-image-container">
              <img src="/static/images/logo-yuepeng.png" alt="" />
            </div>
            <div class="enterprise-info-content">
              <div class="info-row">
                <div class=""></div>
                <div>山东省威海市经济技术开发区香港路18-1号智慧大厦21楼</div>
              </div>
              <div class="info-row">
                <div></div>
                <div>0631-5973390</div>
              </div>
              <div class="info-row">
                <div></div>
                <div>sdyp_intel@163.com</div>
              </div>
              <!-- <div class="record-row">
              <div></div>
              <div class="record-info">Copyright ©2013-现在 千图网 沪ICP备10011451号-6 ICP证书: 沪B2-20180057</div>
            </div> -->
            </div>
          </div>
        </div>
      </div>

      <div class="bottom-content">
        <div class="record-info">
          <span>©2019 山东悦朋智能科技有限公司 版权所有</span>
          <span>备案号：<a href="https://beian.miit.gov.cn/" target="_blank">鲁ICP备19031471号-1</a></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import getMenuData from '@/db/menu.js'
export default {
  components: {
    ListContent: {
      props: {
        listData: {},
      },
      functional: true,
      methods: {
        isActiveRoute(path) {
          if (typeof window === 'undefined') {
            return false
          }
          return path ? new RegExp(path).test(window.location.pathname) : false
        },
      },
      render(h, ctx) {
        const listData = ctx.props.listData
        const isActiveRoute = function (path) {
          if (typeof window === 'undefined') {
            return false
          }
          return path ? new RegExp(path).test(window.location.pathname) : false
        }
        return (
          <div class="link-list">
            {listData
              .filter((item) => item.footer !== false)
              .map((item0, index0) => {
                return (
                  <div class="link-list-level-1" key={index0}>
                    <div class="level-1-title">{item0.label}</div>
                    <div class="children">
                      {item0.children
                        .filter((item) => item.footer !== false)
                        .map((item1, index1) => {
                          return (
                            <div class={['level-2-title', isActiveRoute(item1.url) ? 'is-active' : '']} key={index1}>
                              <a href={item1.url}>{item1.label}</a>
                            </div>
                          )
                        })}
                    </div>
                  </div>
                )
              })}
          </div>
        )
      },
    },
  },
  data() {
    return {
      listData: getMenuData().children,
    }
  },
  mounted() {
    window.onload = function () {
      Media($(window).width())
    }
    $(window).resize(function () {
      Media($(window).width())
    })
    function Media(width) {
      if (width > 750) {
        refreshRem(1920)
      } else {
        refreshRem(750)
      }
    }
    // 根据设计图尺寸计算rem
    function refreshRem(designSize) {
      let html = document.documentElement
      let wW = html.clientWidth // 窗口宽度
      let rem = (wW * 100) / designSize
      document.documentElement.style.fontSize = rem + 'px'
    }
  },
}
</script>
<style lang="scss">
@import '@/assets/css/footer.scss';
</style>
