import conf from './config-customize.json'
const config = {
  // queryType: 'api', // ssr api
  ...conf
}
config.getDocUrl = function(model, id) {
  if (config.queryType === 'ssr') {
    return `/pages/${model}/${id}`
  } else {
    return `/pages/${model}.html?docId=${id}`
  }
}
export default config