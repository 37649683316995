<template>
  <div class="title-bg">
    <div class="container">
      <div class="title-container">
        <div class="img-font">
          <img :src="`/static/images/font-${firstFont}.png`" draggable="false" :alt="firstFont" />
        </div>
        <div class="title-font">
          <div class="title-en">{{ titleEn }}</div>
          <div class="title-zh">{{ titleZh }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    firstFont: {
      type: String,
      default: '',
    },
    titleEn: {
      type: String,
      default: '',
    },
    titleZh: {
      type: String,
      default: '',
    },
  },
}
</script>
<style lang="scss">
@import '@/assets/css/page-title-bg.scss';
</style>
