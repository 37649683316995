/*
 * @Description: 功能描述
 * @Version: 1.0
 * @Author: Yuchuan
 * @Date: 2022-01-21 08:42:08
 */
const getMenuData = function () {
  return {
    children: [
      {
        label: '首页',
        footer: false,
        url: '/index.html',
      },
      {
        label: '走进悦朋',
        url: '/pages/companyProfile.html',
        children: [
          { label: '公司简介', url: '/pages/companyProfile.html' },
          { label: '企业文化', url: '/pages/companyCulture.html' },
          { label: '荣誉资质', url: '/pages/honoraryCertificate.html' },
        ],
      },
      {
        label: '产品中心',
        url: '/pages/product.html',
        children: [
          { label: '产品', url: '/pages/product.html' },
          // { label: '合作客户', url: '/pages/customer.html' },
          { label: '合作案例', url: '/pages/case.html' },
        ],
      },
      {
        label: '新闻中心',
        url: '/pages/industryNews.html',
        children: [
          { label: '行业资讯', url: '/pages/industryNews.html' },
          { label: '公司动态', url: '/pages/companyNews.html' },
        ],
      },
      {
        label: '加入悦朋',
        url: '/pages/friendsImpression.html',
        children: [
          { label: '悦朋印象', url: '/pages/friendsImpression.html' },
          { label: '社会招聘', url: '/pages/socialRecruitment.html' },
          // { label: '校园招聘', url: '/pages/campusRecruitment.html' },
        ],
      },
    ],
  }
}
export default getMenuData
