<template>
  <nav class="yp-navbar navbar navbar-default navbar-fixed-top">
    <div class="container">
      <div class="navbar-header" :class="showBackBtn ? 'show-back-header' : ''">
        <div class="back-btn visible-xs-inline-block" @click="handleBack" v-if="showBackBtn">
          <span class="icon-left">
            <svg t="1644481605615" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6672" width="48" height="48" data-spm-anchor-id="a313x.7781069.0.i3">
              <path
                d="M641.28 278.613333l-45.226667-45.226666-278.634666 278.762666 278.613333 278.485334 45.248-45.269334-233.365333-233.237333z"
                p-id="6673"
                fill="#ffffff"
                data-spm-anchor-id="a313x.7781069.0.i2"
                class="selected"
              ></path>
            </svg>
          </span>
        </div>
        <div class="logo-content">
          <img class="navbar-brand hidden-xs" src="/static/images/small-logo.png" />
          <img v-if="mobileTitle === null" class="visible-xs-inline-block center-logo" src="/static/images/center-logo.png" />
          <div class="visible-xs-inline-block mobile-title" v-else>{{ mobileTitle }}</div>
        </div>
        <button class="navbar-toggle collapsed" type="button" data-target=".navbar-collapse" data-toggle="collapse" aria-expanded="false">
          <!-- <span>Toggle navigation</span> -->
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <!-- <a href="" class="navbar-brand">首页</a> -->
      </div>

      <div class="navbar-collapse collapse">
        <ul class="nav navbar-nav navbar-right">
          <template v-for="(item0, index0) in listData">
            <template v-if="!item0.children">
              <li :key="index0" :class="{ 'is-active': isActiveRoute(item0.url) }">
                <a :href="item0.url">{{ item0.label }}</a>
              </li>
            </template>
            <template v-else>
              <li :key="index0" class="dropdown">
                <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">{{ item0.label }}<span class="caret"></span></a>
                <ul class="dropdown-menu">
                  <template v-for="(item1, index1) in item0.children">
                    <li :key="index1" :class="{ 'is-active': isActiveRoute(item1.url) }">
                      <a :href="item1.url">{{ item1.label }}</a>
                    </li>
                  </template>
                </ul>
              </li>
            </template>
          </template>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import getMenuData from '@/db/menu.js'

export default {
  props: {
    mobileTitle: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      listData: getMenuData().children,
      showBackBtn: false,
    }
  },
  methods: {
    isActiveRoute(path) {
      if (typeof window === 'undefined') {
        return false
      }
      return path ? new RegExp(path).test(window.location.pathname) : false
    },
    dropdownOpen() {
      var $dropdownLi = $('li.dropdown', $(this.$el))

      $dropdownLi
        .mouseover(function () {
          $(this).addClass('open')
        })
        .mouseout(function () {
          $(this).removeClass('open')
        })
    },
    handleBack() {
      window.history.back()
    },
  },
  mounted() {
    window.onload = function () {
      Media($(window).width())
    }
    $(window).resize(function () {
      Media($(window).width())
    })
    function Media(width) {
      if (width > 750) {
        refreshRem(1920)
      } else {
        refreshRem(750)
      }
    }
    // 根据设计图尺寸计算rem
    function refreshRem(designSize) {
      let html = document.documentElement
      let wW = html.clientWidth // 窗口宽度
      let rem = (wW * 100) / designSize
      document.documentElement.style.fontSize = rem + 'px'
    }

    // 点击空白处关闭菜单
    const thisJQ = $(this.$el)
    const collapseJQ = $('.navbar-collapse', thisJQ)
    collapseJQ.one('show.bs.collapse', () => {
      $(document.body).on('click', (e) => {
        if (thisJQ.find($(e.target)).length === 0) {
          collapseJQ.collapse('hide')
        }
      })
    })
    // pc端的时候鼠标悬浮自动显示下拉框
    if (window.screen.width >= 768) {
      this.dropdownOpen()
    }
    if (document.referrer !== '') {
      this.showBackBtn = true
    }
  },
}
</script>
<style lang="scss">
@import '@/assets/css/navbar.scss';
</style>
